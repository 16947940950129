import React, { Fragment } from 'react'
import { serialize } from 'dom-form-serializer'

import './Form.css'

class Form extends React.Component {
  static defaultProps = {
    successMessage: 'Zpráva byla úspěšně odeslána.',
    errorMessage:
      'Objevil se problém a Vaše zpráva nebyla odeslána. Prosím kontaktujte nás emailem, nebo nám zavolejte.'
  }

  state = {
    alert: ''
  }

  handleSubmit = e => {
    e.preventDefault()

    const form = e.target;
    const data = serialize(form);
    const encode = data => {
      return Object.keys(data)
        .map(
          key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&')
    };
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        ...data
      })
    })
      .then(() => {
        form.reset()
        this.setState({
          alert: this.props.successMessage
        })
      })
      .catch(err => {
        console.error(err)
        this.setState({
          alert: this.props.errorMessage
        })
      })
  }

  render() {
    return (
      <Fragment>
      <form
        name="callToActionForm"
        method="POST"
        data-netlify="true"
        className="Form"
        onSubmit={this.handleSubmit}
      >
        <input type="hidden" name="form-name" value="callToActionForm" />
        {console.log(this.state.alert)}
        {this.state.alert && (
            <div className="Form--Alert">{this.state.alert}</div>
          )}
        <label className="Form--Label">
          <input
            className="Form--Input Form--InputText"
            type="text"
            placeholder="Vaše telefonní číslo, nebo emailová adresa"
            name="contact"
            required
          />
          <span>Vaše telefonní číslo, nebo emailová adresa</span>
        </label>
        <button type="submit" className="Button">
          Odeslat
        </button>
      </form>
      </Fragment>
    )
  }
}

export default Form
